<template>
  <div class="pa-3 _bg-default">
    <div class="d-flex justify-end px-5"><v-btn @click="logOut">Logout</v-btn></div>
    <div class="d-flex justify-center">
      <h1>Pelatihan sudah berakhir.</h1>
    </div>
    <div>
      <v-row>
        <v-col cols="12" md="7">
          <LeaderboardUser></LeaderboardUser>
        </v-col>
        <v-col cols="12" md="5">
          <LeaderboardTeam></LeaderboardTeam>
        </v-col>
      </v-row>
    </div>
    <div class="d-flex justify-center">
      <v-col cols="12" md="7">
        <v-card v-if="profile">
          <v-toolbar color="indigo" dense dark><h3>Nilai</h3> </v-toolbar>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">NO</th>
                  <th class="text-center">Fase</th>
                  <th class="text-center">Widyaiswara</th>
                  <th class="text-center">Coach</th>
                  <th class="text-center">Rata-rata</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">1</td>
                  <td class="text-center">INDIVIDU</td>
                  <td class="text-center">
                    {{ profile.student.nilai_objektif_teacher }}
                  </td>
                  <td class="text-center">
                    {{ profile.student.nilai_objektif_mentor }}
                  </td>
                  <td class="text-center">{{ averageIndividu }}</td>
                </tr>
                <tr>
                  <td class="text-center">2</td>
                  <td class="text-center">KELOMPOK</td>
                  <td class="text-center">
                    {{ profile.grup.nilai_objektif_teacher }}
                  </td>
                  <td class="text-center">
                    {{ profile.grup.nilai_objektif_mentor }}
                  </td>
                  <td class="text-center">{{ averageKelompok }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </div>
  </div>
</template>

<script>
import { GET_NILAI_INDIVIDU } from "../graphql/graphql";
import {
  TUGAS_INDIVIDU_BY_STUDENT_ID,
  TUGAS_GRUP_BY_GRUPID,
} from "../graphql/Student";
import LeaderboardUser from "../components/Student/Leaderboard/LeaderboardUser";
import LeaderboardTeam from "../components/Student/Leaderboard/LeaderboardTeam";
export default {
  name: "end_evt",
  apollo: {
    tugasIndividuByStudentId: {
      query: TUGAS_INDIVIDU_BY_STUDENT_ID,
      variables() {
        return {
          student_id: parseInt(localStorage.getItem("id")),
        };
      },
    },
    tugasGrupByGrupId: {
      query: TUGAS_GRUP_BY_GRUPID,
      variables() {
        return {
          grup_id: this.grup_id,
        };
      },
      skip() {
        return !this.grup_id;
      },
    },
    profile: {
      query: GET_NILAI_INDIVIDU,
    },
  },
  components: {
    LeaderboardUser,
    LeaderboardTeam,
  },
  computed: {
    averageIndividu() {
      let hasil = null;
      if (!this.loader) {
        const teacher = this.profile.student.nilai_objektif_teacher;
        const coach = this.profile.student.nilai_objektif_mentor;
        hasil = (teacher + coach) / 2;
      }
      return hasil;
    },
    averageKelompok() {
      let hasil = null;
      if (!this.loader) {
        const teacher = this.profile.grup.nilai_objektif_teacher;
        const coach = this.profile.grup.nilai_objektif_mentor;
        hasil = (teacher + coach) / 2;
      }
      return hasil;
    },
    tugasIndividu() {
      var tugas = null;
      if (this.tugasIndividuByStudentId) {
        tugas = this.tugasIndividuByStudentId.slice(-1)[0];
      }
      return tugas;
    },
    tugasKelompok() {
      var tugas = null;
      if (this.tugasGrupByGrupId) {
        tugas = this.tugasGrupByGrupId.slice(-1)[0];
      }
      return tugas;
    },
    grup_id() {
      let id = null;
      if (this.userProfile) {
        id = parseInt(this.userProfile.grup.id);
      }
      return id;
    },
  },

  data() {
    return {
      loading: false,
      loader_grup: false,
      tugasIndividuByStudentId: [],
      env: process.env.VUE_APP_GRAPHQL,
      tugasGrupByGrupId: [],
      tugas_id: null,
      user_id: localStorage.getItem('id'),
      student_id: null,
      myfile: null,
      myfile_grup: null,
      keterangan: "",
      nama_lengkap: "",
      nama_lengkap_grup: "",
      profile: null,
      tugas: 1,
      dialogPlay: false,
      dialogAttention: false,
      fase: localStorage.getItem("fase"),
    };
  },
  mounted() {
    this.getRank()
  },
  methods: {
    logOut() {
      localStorage.clear();
      this.$router.go("/login");
    },
    getRank() {
      this.$store.dispatch("leaderboard/leadY", {
        page: 1,
        per_page: 5,
        id: parseInt(this.user_id),
      }).then((data) => {
        console.log(data);
      }); //get leaderboard individu
      this.$store.dispatch("leaderboard/leadX", {
        page: 1,
        per_page: 5,
        id: parseInt(this.user_id),
      }).then((data) => {
        console.log(data);
      }); //get leaderboard kelompok
    },
  },
};
</script>

<style scoped>
._bg-default {
  background: #f4f6f9;
  min-height: 100vh;
}
</style>